












































































  .deadDetail {
    display:none;
    position: absolute;
    line-height: 4px;
    left: -5%;
    top: -27px;
    box-shadow: 0 0 10px rgba(0,0,0,.2);
    color: #000;
    font-size: 12px;
    text-align: center;
    box-sizing: border-box;
    z-index: 2;
    transition:.2s;
      .mod {
        position: relative;
        min-width: 142px;
        color: white;
        padding: 3px 64px 3px 11px;
        height: 33px;
        z-index: 2;
        box-sizing: border-box;
        background: rgba(0,0,0,.6);
        p{
          line-height: 26px;
        }
      }
      .btnCopy {
        position: absolute;
        right: 0;
        background: #28CCCD;
        top: 0px;
        color: white;
        padding: 14.6px 15px;
        cursor: pointer;
      }
      .mod:before, .deadDetail .mod:after {
        position: absolute;
        content: '';
        top: 33px;
        transform: rotate(180deg);
        left: 50%;
        margin-left: -6px;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid rgba(0,0,0,.5);
        z-index: 2;
       }
}
.new_style{
  display: inline-block !important;
  max-width: 500px !important;
  max-height: 500px !important;
  overflow: auto !important;
  text-overflow: 	clip !important;
  white-space: normal !important;
  word-break: break-all;
}
.new_style + .btnCopy{
  display: flex;
  /*实现垂直居中*/
  align-items: center;
  /*实现水平居中*/
  justify-content: center;
}
