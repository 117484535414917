























































































































* {
    padding: 0;
    margin: 0;
    list-style: none;
}

.pop {}

.mask {
    position: fixed;
    width: 100%;
    height: 100%;
    background: red;
    z-index: 996;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
}

.popMain {
    z-index: 997;
    background-color: #ffffff;
    border-radius: 4px;
    position: fixed;
    top: 50%;
    left: 51%;
    transform: translateX(-51%) translateY(-50%);
    padding-right: 58px;
    padding-left: 58px;
    padding-bottom: 50px;
    min-width: 310px;
}

.popClose {
    // width: 14px;
    // height: 14px;
    position: absolute;
    right: 12px;
    top: 12px;
    z-index: 997;
    cursor: pointer;
    font-size: 14px;
    color: #333333;
}
.popClose:hover{
    color: #28cccc;
}

.popTitlBox {
    width: 100%;
    text-align: center;
    margin-top: 29px;
}

.popWarn {
    width: 63px;
    height: 63px;
    margin-bottom: 12px;
}

.popTitl {
    font-size: 20px;
    color: #333333;
    font-weight: bold;
    /* margin-top: 12px; */
    line-height: 27px;

    word-break: break-all;
    text-align: center;
}
.xl_leftAlign{
    max-width: 400px;
    text-align: left !important;
}
.popBtn_warm_pop {
    width: 100%;
    text-align: center;
    margin-top: 38px;
}

.popBtn_warm_pop button {
    /* width: 94px;
    height: 32px;
    display: inline-block;
    border-radius: 4px;
    border: solid 1px #19b0b0;
    font-size: 14px;
    color: #19b0b0; */
    margin: 0 26px;
    /* line-height: 32px;
    cursor: pointer; */
}

.popBtn div:hover {
    background-image: linear-gradient(-22deg, #28cccc 0%, #73e2e2 100%);
    color: #fff;
    border: solid 1px #fff;
}
.popBtn div a:hover{
    color: #fff;
}
.xl_cancle_btn{
    border:1px solid rgba(229,229,229,1) !important;
    color:rgba(153,153,153,1) !important;
}
.xl_cancle_btn:hover{
    border:1px solid rgba(229,229,229,1) !important;
    color:rgba(153,153,153,1) !important;
    background-image: none !important;
    background-color: none !important;
}
